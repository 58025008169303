<template>
  <v-container>
    <v-card class="rounded-corner-0">
      <div class="amber accent-3" style="height: 5px; width: 100%"></div>
      <v-row no-gutters>
        <v-card-title style="width: 100%">
          <!-- Logo da conta -->
          <v-col cols="3" v-if="conta.logo">
            <v-img
              :src="conta.logo"
              aspect-ratio="1.4"
              contain
              max-height="80px"
            ></v-img>
          </v-col>
          <!-- Dados da conta -->
          <v-col cols="9" v-if="conta">
            <v-row>
              <div class="font-weight-black body-1 text-h6">
                {{ conta.razao_social }}
              </div>
            </v-row>

            <v-row class="body-2">
              {{ conta.logradouro }}
              {{
                conta.municipio_descricao
                  ? conta.estado_uf
                    ? `, ${conta.municipio_descricao} /`
                    : `, ${conta.municipio_descricao}`
                  : ''
              }}
              {{ conta.estado_uf }} - {{ pais }}.
            </v-row>

            <v-row class="body-2"> {{ safra }} </v-row>
          </v-col>
        </v-card-title>
      </v-row>

      <!-- Fazenda -->
      <v-row v-if="fazenda && programa">
        <v-col cols="6">
          <div class="font-weight-black text-h6 ml-4">{{ fazenda.nome }}</div>
        </v-col>

        <v-col cols="6">
          <div class="float-right mr-3 caption">
            {{ fazenda.latitude }}, {{ fazenda.longitude }} -
            {{ programa.municipio.nome }}/{{ programa.estado.uf }} -
            {{ pais }}
          </div>
        </v-col>
      </v-row>
    </v-card>

    <!-- Cultivar -->
    <v-card class="grey lighten-3 mt-5" v-if="semente && programa">
      <v-row>
        <v-col cols="4">
          <div class="font-weight-black text-h6 ml-4">
            <b>{{ semente.cultivar }}</b>
          </div>
          <div class="grey--text text-subtitle-1 ml-4">
            {{ semente.asset_owner }}
          </div>
        </v-col>

        <v-col cols="1"> </v-col>

        <v-col cols="7">
          <div style="float: right; font-size: 12px" class="mr-4 grey--text">
            {{ $t('cultivar.gmr') }}: {{ semente.grupo }}
          </div>
          <br />
          <div style="float: right; font-size: 12px" class="mr-4 grey--text">
            {{ $t('cultivar.epoca_semeadura') }}: {{ epocaInicio }} -
            {{ epocaFim }}
          </div>
        </v-col>
      </v-row>
      <br />
    </v-card>

    <!-- Programa -->
    <v-row no-gutters align="end" v-if="programa">
      <v-col cols="4 pl-4">
        <div class="font-weight-black text-h6">
          {{ programa.programa.nome }}
        </div>
        <div class="body-2">{{ $t('semeadura_em', [dataSemeadura]) }}</div>
      </v-col>

      <v-col cols="4">
        <div>
          <div class="text-center">
            <b> {{ programa.planejamentos.length }} </b>
          </div>
          <div class="text-center">{{ $t('aplicacao.aplicacoes') }}</div>
        </div>
      </v-col>

      <v-col cols="4 pr-4">
        <v-row justify="end" align="end" no-gutters>
          <v-col>
            <div class="text-right">
              {{ custoTotalSc }}
              {{ $unitMeasures['performance'][$currentLanguage()] }}
            </div>
            <div class="text-right text-h6">
              <b>
                {{ $currency(custoTotal) }}/{{
                  $unitMeasures['area'][$currentLanguage()]
                }}
              </b>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment'
import { convertToTargetUnit } from '@/components/Form/converter'

export default {
  name: 'ReportsHeader',
  props: {
    programa: {
      default: () => {},
      type: Object,
    },
    fazenda: {
      default: () => {},
      type: Object,
    },
    conta: {
      default: () => {},
      type: Object,
    },
    semente: {
      default: () => {},
      type: Object,
    },
    safra: {
      default: '',
      type: String,
    },
  },

  data() {
    return {
      pais: this.programa.estado.pais_id == 1 ? 'Brasil' : 'Paraguay',
    }
    /* 
        FIX-ME: Ajustar para pegar nome do país correto dentro do projeto das unidades de medida
    */
  },

  computed: {
    epocaInicio() {
      return !this.programa
        ? ''
        : this.getDate(this.programa.cultivar_regiao_parametro.semeadura_ini)
    },
    epocaFim() {
      return !this.programa
        ? ''
        : this.getDate(this.programa.cultivar_regiao_parametro.semeadura_fim)
    },
    dataSemeadura() {
      return !this.programa
        ? ''
        : this.getDate(this.programa.programa.data_prevista)
    },
    custoTotal() {
      const precoFloat = (d) => (d.preco !== null ? d.preco * d.dose : 0)
      const pegaPrecos = (p) => p.planejamento_defensivos.map(precoFloat)
      const flatten = (acumulador, precos) => acumulador.concat(precos)
      const somaTudo = (preco1, preco2) => preco1 + preco2
      return this.programa.planejamentos
        .map(pegaPrecos)
        .reduce(flatten, [])
        .reduce(somaTudo, 0)
        .toFixed(2)
    },

    bagPrice() {
      return this.fazenda.bag_price.length
        ? this.fazenda.bag_price.find(
            (bag) => bag.crop_id === this.programa.semente.crop_id
          ).price
        : this.fazenda.valor_saca
    },

    custoTotalSc() {
      return convertToTargetUnit(this.custoTotal / this.bagPrice, 'weight')
    },
  },

  methods: {
    getDate(d) {
      return moment(d, 'YYYY/MM/DD')
        .format('YYYY/MM/DD')
        .toString()
        .formatDate()
    },
  },
}
</script>

<style scoped>
.rounded-corner-0 {
  border-radius: 0px !important;
}

.print-only {
  display: none;
}

@media print {
  .print-only {
    display: block;
  }
}
</style>
