<template>
  <v-container>
    <v-card class="header-farm">
      <div class="yellow-botton-bar"></div>
      <v-row class="pa-5">
        <v-col cols="4" class="d-flex align-center ml-5">
          <div>
            <label>{{ $t('report.farm_report') }}</label>
            <h3>
              <p class="farm-name">
                {{ farm.name }}
              </p>
            </h3>
          </div>
        </v-col>
        <v-col cols="4" class="d-flex align-top">
          <v-divider class="vertical-divider mr-5" vertical> </v-divider>
          <div>
            <font-awesome-icon
              class="color-custom-grey"
              icon="map-marker-alt"
            />
            <label class="ml-2">{{ $t('report.localization') }}</label>
            <p class="ml-5 complementary-data">
              {{ farm.city }} - {{ farm.state }}
            </p>
          </div>
        </v-col>
        <v-col cols="3" class="d-flex align-top">
          <div>
            <label class="ml-2 ml-sm-0">{{ $t('report.who_printed') }}</label>
            <h3>
              <p class="complementary-data">
                {{ account.user.name }}
              </p>
            </h3>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'SeasonReportHeaderFarm',
  props: {
    account: { type: Object, required: true },
    farm: { type: Object, required: true },
  },
}
</script>

<style scoped>
.yellow-botton-bar {
  background: #f3cd12;
  min-height: 5px;
}

.header-farm label {
  font-size: 12px;
  color: #aab2a9;
  font-weight: bold;
}

.header-farm p {
  font-weight: bold;
}

.header-farm .complementary-data {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #3f4e3c;
}

.header-farm .farm-name {
  font-size: 28px;
}

.color-custom-grey {
  color: #aab2a9;
}

@media print {
  .header-farm .farm-name {
    font-size: 20px;
  }

  .header-farm label {
    font-size: 9px;
  }

  .header-farm .complementary-data {
    font-size: 12px;
  }
}
</style>
