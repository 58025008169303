<template>
  <v-container>
    <v-card>
      <div class="pa-12 list-defensives">
        <v-row>
          <v-col>
            <div>
              <label>{{ $t('report.report') }}</label>
              <h3>
                <p>{{ $t('report.fungicides_applied') }}</p>
              </h3>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex justify-start">
            <label>{{ $t('report.product') }}</label>
          </v-col>
          <v-col class="d-flex justify-center">
            <label>{{ $t('report.liters') }}</label>
          </v-col>
          <v-col class="d-flex justify-center">
            <label>{{ $t('report.kilos') }}</label>
          </v-col>
          <v-col class="d-flex justify-end">
            <label>{{ $t('report.value') }}</label>
          </v-col>
        </v-row>
        <v-row
          class="page-break-inside"
          :class="{ backgroundlightGray: indexDefensiveIsOdd(defensive) }"
          v-for="defensive in defensives"
          :key="defensive.id"
        >
          <v-col class="d-flex align-center justify-start">
            {{ defensive.name }}
          </v-col>

          <v-col class="d-flex align-center justify-center color-light-gray">
            <div v-if="defensive.unit === 'L'">
              {{ defensive.quantity_area.toFixed(2) }}
              {{ defensive.unit }}
            </div>
          </v-col>

          <v-col class="d-flex align-center justify-center color-light-gray">
            <div v-if="defensive.unit === 'kg'">
              {{ defensive.quantity_area.toFixed(2) }}
              {{ defensive.unit }}
            </div>
          </v-col>

          <v-col class="d-flex align-center justify-end color-light-gray">
            {{ $currency(defensive.price_area).replace(/[^0-9.,]+/g, '') }}
          </v-col>
        </v-row>

        <v-row class="total">
          <v-col class="d-flex justify-start"> TOTAL </v-col>

          <v-col class="d-flex align-center justify-center">
            {{ totalLiters.toFixed(2) }} L
          </v-col>

          <v-col class="d-flex align-center justify-center">
            {{ totalKilos.toFixed(2) }} kg
          </v-col>

          <v-col class="d-flex justify-end">
            {{ $currentCurrency() }}
            {{ $currency(totalPrice).replace(/[^0-9.,]+/g, '') }}
          </v-col>
        </v-row>
      </div>
      <div class="green-botton-bar"></div>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'SeasonReportListDefensives',
  props: {
    defensives: { type: Array, required: true },
  },

  data() {
    return {
      liters: 'L',
      kilos: 'kg',
    }
  },
  methods: {
    indexDefensiveIsOdd(defensive) {
      return this.defensives.indexOf(defensive) % 2 === 0
    },
  },

  computed: {
    totalPrice() {
      return this.defensives.reduce(
        (accumulator, defensive) =>
          parseFloat(accumulator) + parseFloat(defensive.price_area),
        0.0
      )
    },

    totalLiters() {
      return this.defensives.reduce(
        (accumulator, defensive) =>
          defensive.unit === this.liters
            ? parseFloat(accumulator) + parseFloat(defensive.quantity_area)
            : accumulator,
        0.0
      )
    },

    totalKilos() {
      return this.defensives.reduce(
        (accumulator, defensive) =>
          defensive.unit === this.kilos
            ? parseFloat(accumulator) + parseFloat(defensive.quantity_area)
            : accumulator,
        0.0
      )
    },
  },
}
</script>

<style scoped>
.backgroundlightGray {
  background: #f0f0f0;
}

.list-defensives label {
  font-size: 12px;
  color: #aab2a9;
  font-weight: bold;
}

.list-defensives p {
  font-size: 22px;
  font-weight: bold;
}

.color-light-gray {
  color: #aab2a9;
}

.green-botton-bar {
  background: green;
  min-height: 5px;
}

.list-defensives .total {
  font-weight: bold;
}

@media print {
  .list-defensives label {
    font-size: 10px;
  }

  .list-defensives p {
    font-size: 16px;
  }

  .list-defensives .total {
    font-size: 10px;
  }

  .list-defensives {
    font-size: 10px !important;
  }
}
</style>
