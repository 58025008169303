<template>
  <v-container class="pa-0">
    <v-row align="center" no-gutters>
      <v-col />

      <!-- # da aplicação e data prevista -->
      <v-col cols="1">
        <v-card align="center" class="rounder-corner-25">
          <div class="text-h6" align="center" justify="center">
            <b>{{ planejamento.aplicacao }}°</b>
          </div>
          <div
            v-if="showDates"
            class="black white--text font-weight-black"
            align="center"
            justify="center"
          >
            {{ day }}
            <br />
            {{ month }}
          </div>
        </v-card>
      </v-col>

      <!-- Produtos -->
      <v-col cols="3">
        <div class="ml-4" style="font-size: 12px">
          <div style="font-size: 12px; color: grey">
            <b> {{ $t('programa.report.produtos_doses') }} </b>
          </div>
          <br />
          <div
            class="body-2"
            v-for="defensivo in planejamento.planejamento_defensivos"
            :key="defensivo.id"
          >
            {{ defensivo.defensivo.nome }} - {{ defensivo.dose }}
            {{ defensivo.defensivo.unidade_medida.replace('_', ' ') }}
          </div>
        </div>
      </v-col>

      <v-col cols="3">
        <efficacies
          v-if="displayEfficacies"
          :spraying="planejamento"
          is-planning
          :width="300"
        />
      </v-col>

      <!-- Custos -->
      <v-col cols="4">
        <v-row align="end" justify="end">
          <v-col>
            <div class="text-right">
              {{ custoSc }}
              {{ $unitMeasures['performance'][$currentLanguage()] }}
            </div>
            <div class="text-right">
              <b
                >{{ $currency(custo) }}/{{
                  $unitMeasures['area'][$currentLanguage()]
                }}</b
              >
            </div>
          </v-col>
        </v-row>
      </v-col>

      <v-col />
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment'
import Efficacies from '@/components/Efficacies/index.vue'
import { convertToTargetUnit } from '@/components/Form/converter'

export default {
  name: 'ProgramItem',

  components: {
    Efficacies,
  },

  props: {
    displayEfficacies: {
      type: Boolean,
      default: false,
    },
    planejamento: {
      type: Object,
      required: true,
    },
    bagPrice: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    custo() {
      const precoFloat = (d) => (d.preco !== null ? d.preco * d.dose : 0)
      const somaTudo = (preco1, preco2) => preco1 + preco2
      return this.planejamento.planejamento_defensivos
        .map(precoFloat)
        .reduce(somaTudo, 0)
        .toFixed(2)
    },

    custoSc() {
      return !this.bagPrice
        ? 0
        : convertToTargetUnit(this.custo / this.bagPrice, 'performance')
    },

    day() {
      return moment(
        this.planejamento.data_aplicacao_planejada,
        'YYYY/MM/DD'
      ).format('D')
    },

    month() {
      return moment(this.planejamento.data_aplicacao_planejada, 'YYYY/MM/DD')
        .locale('pt-br')
        .format('MMM')
    },

    showDates() {
      return process.env.VUE_APP_REPORT_PROGRAM_DATES || false
    },
  },
}
</script>

<style scoped>
.rounder-corner-25 {
  border: 0.2px solid black;
  border-radius: 35px;
}
</style>
