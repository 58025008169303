<template>
  <v-container>
    <v-row class="header-total ml-5">
      <v-col cols="4">
        <div>
          <label>{{ $t('report.total_of_sprays') }}</label>
          <p class="total">
            {{ totalSpraysAverage }}
          </p>
        </div>
      </v-col>
      <v-col cols="4">
        <div>
          <label>{{ $t('report.average_harvest_per_plot') }}</label>
          <p class="total">
            {{ harvestAverage }}
            <label>{{
              $unitMeasures['performance'][$currentLanguage()]
            }}</label>
          </p>
        </div>
      </v-col>
      <v-col cols="4">
        <div>
          <label>{{ $t('report.total_value_of_defensives_used') }}</label>
          <p class="total">
            <label>{{ $currentCurrency() }}</label>
            {{ $currency(totalPriceDefensivesUsed).replace(/[^0-9.,]+/g, '') }}
          </p>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'SeasonReportHeaderTotal',
  props: {
    total: { type: Object, required: true },
  },

  computed: {
    totalPriceDefensivesUsed() {
      return parseFloat(this.total.defensives_price_used)
    },
    totalSpraysAverage() {
      return (
        this.total.count_completed_sprays / this.total.count_sowings
      ).toFixed(2)
    },
    harvestAverage() {
      return this.total.harvest_average
    },
  },
}
</script>

<style scoped>
.container {
  line-height: 1;
}

.header-total label {
  font-size: 12px;
  color: #aab2a9;
  font-weight: bold;
}

.header-total .total {
  font-size: 30px !important;
  color: black !important;
  font-weight: bold;
  margin-top: 8px;
}

.header-total .total label {
  font-size: 18px;
  font-weight: bold;
}

@media print {
  .header-total .total {
    font-size: 18px !important;
  }

  .header-total .total label {
    font-size: 13px !important;
  }

  .header-total label {
    font-size: 7px !important;
  }
}
</style>
