<template>
  <v-container>
    <v-card class="page-break-end">
      <div class="pa-12 list-defensives">
        <v-row>
          <v-col>
            <div>
              <label>{{ $t('report.report') }}</label>
              <h3>
                <p>{{ $t('report.varieties_used') }}</p>
              </h3>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex justify-start">
            <label>{{ $t('report.variety') }}</label>
          </v-col>
          <v-col class="d-flex justify-center">
            <label>{{ $t('report.company') }}</label>
          </v-col>
          <v-col class="d-flex justify-end">
            <label>{{ $t('report.amount_per_hectare') }}</label>
          </v-col>
        </v-row>
        <v-row
          :class="{ backgroundlightGray: indexVarietiesIsOdd(variety) }"
          v-for="variety in varieties"
          :key="variety.id"
          class="page-break-inside"
        >
          <v-col class="d-flex align-center justify-start">
            {{ variety.name }}
          </v-col>
          <v-col class="d-flex align-center justify-center color-light-gray">
            {{ variety.company_name }}
          </v-col>
          <v-col class="d-flex align-center justify-end color-light-gray">
            {{ variety.sowing_area.toFixed(2) }}
          </v-col>
        </v-row>
      </div>
      <div class="green-botton-bar"></div>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'SeasonReportListVarieties',
  props: {
    varieties: { type: Array, required: true },
  },

  methods: {
    indexVarietiesIsOdd(variety) {
      return this.varieties.indexOf(variety) % 2 === 0
    },
  },
}
</script>

<style scoped>
.backgroundlightGray {
  background: #f0f0f0;
}

.list-defensives label {
  font-size: 12px;
  color: #aab2a9;
  font-weight: bold;
}

.list-defensives p {
  font-size: 22px;
  font-weight: bold;
}

.color-light-gray {
  color: #aab2a9;
}

.green-botton-bar {
  background: green;
  min-height: 5px;
}

@media print {
  .list-defensives label {
    font-size: 10px;
  }

  .list-defensives p {
    font-size: 16px;
  }

  .list-defensives .total {
    font-size: 10px;
  }

  .list-defensives {
    font-size: 10px !important;
  }
}
</style>
