<template>
  <v-container class="not-spray-avaliable">
    <div class="pa-10">
      <v-row class="pa-3">
        <p style="color: grey">
          <font-awesome-icon
            class="fa-lg mb-1 color-custom-green"
            icon="spray-can"
          />
          <label class="ml-5 sprays">{{ $t('report.sprays') }}</label>
        </p>
      </v-row>
      <v-row class="page-break-inside">
        <v-col cols="12" class="font-color-grey">
          {{ $t('report.not_sprays_avaliable') }}
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
export default { name: 'SpraysNotAvaliable' }
</script>

<style scoped>
.not-spray-avaliable.font-color-grey {
  color: grey;
}

.not-spray-avaliable label {
  font-size: 12px;
  color: #aab2a9;
  font-weight: bold;
}

.not-spray-avaliable p {
  font-size: 16px;
  color: #aab2a9;
}

.sprays {
  color: grey;
  font-size: 20px !important;
}

.color-custom-green {
  color: green;
  opacity: 0.6;
}

@media print {
  .not-spray-avaliable label {
    font-size: 6px;
  }

  .not-spray-avaliable p {
    font-size: 10px;
  }

  .page-break-inside {
    break-inside: avoid;
  }
}
</style>
