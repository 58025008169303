<template>
  <v-container>
    <v-row no-gutters>
      <!-- Severidade -->
      <v-col cols="3">
        <div class="ml-4 text-left grey--text body-2">
          {{ $t('programa.report.projecao_para') }}
        </div>
        <div class="ml-4 text-left body-1">
          <b>{{ severidade }}</b>
        </div>
      </v-col>

      <v-col> </v-col>

      <!-- Usuário -->
      <v-col cols="4" class="mr-4">
        <div class="text-right body-2">
          <b>{{ `${usuario.titulo || ''} ${usuario.nome}` }}</b>
        </div>
        <div
          v-if="usuario.orgao && usuario.registro"
          class="text-right grey--text body-2"
        >
          {{ `${usuario.orgao} ${usuario.registro}` }}
        </div>
        <div class="text-right grey--text caption">
          {{ $t('programa.report.em', [hoje]) }}
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ProgramFooter',
  props: {
    programa: {
      default: () => {},
      type: Object,
    },
    usuario: {
      default: () => {},
      type: Object,
    },
  },

  data() {
    return {
      hoje: new Date().toLocaleDateString(),
    }
  },

  computed: {
    severidade() {
      if (!this.programa) return ''

      switch (this.programa.programa.severidade_id) {
        case 1:
          return this.$t('severidade.alta')
        case 2:
          return this.$t('severidade.media')
        default:
          return this.$t('severidade.baixa')
      }
    },
  },
}
</script>
