<template>
  <v-container class="season-report">
    <div
      v-if="digifarmz && account && farm"
      style="min-width: 680px !important"
    >
      <header-account
        :digifarmz="digifarmz"
        :account="account"
      ></header-account>

      <header-farm :farm="farm" :account="account"></header-farm>

      <header-total :total="total"></header-total>

      <list-defensives :defensives="defensives"></list-defensives>

      <list-varieties :varieties="varieties"></list-varieties>

      <performance :plots="plots"></performance>

      <footer-account :account="account"></footer-account>
    </div>
  </v-container>
</template>

<script>
import HeaderAccount from '@/modules/reports/components/season-report/HeaderAccount.vue'
import HeaderFarm from '@/modules/reports/components/season-report/HeaderFarm.vue'
import HeaderTotal from '@/modules/reports/components/season-report/HeaderTotal.vue'
import ListDefensives from '@/modules/reports/components/season-report/ListDefensives.vue'
import ListVarieties from '@/modules/reports/components/season-report/ListVarieties.vue'
import Performance from '@/modules/reports/components/season-report/performance'
import FooterAccount from '@/modules/reports/components/season-report/Footer.vue'
import reportService from '@/modules/reports/services/reportService'
import api from '@/services/api'

export default {
  name: 'SeasonReport',
  components: {
    HeaderAccount,
    HeaderFarm,
    HeaderTotal,
    ListDefensives,
    ListVarieties,
    Performance,
    FooterAccount,
  },

  data() {
    return {
      digifarmz: {
        logo: 'img/digifarmz_yellow.webp',
      },
      account: null,
      farm: null,
      plots: null,
      defensives: null,
      currentUser: null,
    }
  },

  async mounted() {
    await this.getSeason()
    await this.waitAndPrint()
  },

  async created() {
    const { data } = await api.menu.usuario()
    this.currentUser = data
  },

  computed: {
    varieties() {
      const sowings = this.getSowings()

      return sowings.reduce(function (accumulator, sowing) {
        const variety = sowing.variety

        variety.sowing_area = parseFloat(sowing.sowing_area)

        const repited = accumulator.find((v) => v.id === variety.id)

        if (repited) {
          repited.sowing_area = repited.sowing_area + variety.sowing_area
        } else {
          accumulator.push(sowing.variety)
        }

        return accumulator
      }, [])
    },
  },

  methods: {
    async getSeason() {
      const response = await reportService.seasonReport(
        this.$route.query.id,
        this.$route.query.startPeriod,
        this.$route.query.endPeriod,
        this.$route.query.isSoybean,
        this.$route.query.isWheat
      )

      this.account = {
        user: { name: this.currentUser.name },
        name: this.currentUser.account.name,
        logo: this.currentUser.logo,
      }

      this.farm = response.data.farm
      this.total = response.data.total
      this.plots = response.data.plots
      this.defensives = response.data.defensives
    },

    waitAndPrint() {
      setTimeout(() => {
        window.print()
      }, 1000)
    },

    getSowings() {
      const sowings = this.plots.reduce((accumulator, plot) => {
        return accumulator.concat(plot.sowings)
      }, [])
      return sowings
    },
  },
}
</script>

<style lang="scss">
.season-report {
  padding: 0px;
  width: 24cm !important;
  h3,
  label,
  p {
    font-family: 'Rubik', sans-serif;
  }
}

@media print {
  * {
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari */
    color-adjust: exact !important; /*Firefox*/
  }

  .page-break-begin {
    page-break-before: always;
  }

  .page-break-inside {
    break-inside: avoid;
  }

  .page-break-end {
    page-break-after: always;
  }

  .v-image {
    max-height: 30px !important;
    max-width: 150px !important;
  }
}
</style>
