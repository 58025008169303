<template>
  <v-container>
    <v-card class="header-variety">
      <v-row class="pa-1">
        <v-col
          cols="2"
          class="header-variety__stage-circle d-flex justify-center align-center"
          v-if="sowing.stage"
        >
          <v-progress-circular
            :rotate="360"
            :size="55"
            :width="3"
            :value="sowing.stage.percentage"
            color="green"
          >
            <label
              style="
                color: black !important;
                font-size: 14px !important;
                font-weight: normal !important;
              "
              >{{ sowing.stage.description }}</label
            >
          </v-progress-circular>
        </v-col>
        <v-col
          cols="2"
          class="d-flex align-center justify-start"
          :offset="!sowing.stage ? 2 : 0"
        >
          <div>
            <label>{{ $t('report.genetics') }}</label>
            <p class="variety">
              {{ sowing.variety.name }}
            </p>
            <label style="text-transform: uppercase">
              {{ plot.name }} - {{ sowingDateFormatted }}
            </label>
          </div>
        </v-col>
        <v-col cols="1" class="d-flex align-center justify-center">
          <div>
            <label>{{ $t('report.culture') }}</label>
            <p>{{ $t(crop) }}</p>
          </div>
        </v-col>
        <v-col cols="2" class="d-flex align-center justify-center">
          <div>
            <label>{{ $t('report.area') }}</label>
            <p>
              {{ sowingArea }} {{ $unitMeasures['area'][$currentLanguage()] }}
            </p>
          </div>
        </v-col>
        <v-col v-if="sowingHasProduction" cols="2" class="d-flex align-center">
          <div>
            <label>{{ $t('report.production') }}</label>
            <p>
              {{ sowingProduction }}
              {{ $unitMeasures['weight'][$currentLanguage()] }}
            </p>
          </div>
        </v-col>
        <v-col v-if="sowingHasProduction" cols="2" class="d-flex align-center">
          <div>
            <label>{{ $t('report.productivity') }}</label>
            <p>
              {{ productivity }}
              {{ $unitMeasures['performance'][$currentLanguage()] }}
            </p>
          </div>
        </v-col>
        <harvest-unavailable-alert
          v-if="!sowingHasProduction"
        ></harvest-unavailable-alert>
      </v-row>
      <hr />
      <list-spray v-if="sowing.sprays" :sprays="sowing.sprays"></list-spray>
      <sprays-not-avaliable v-else></sprays-not-avaliable>
    </v-card>
  </v-container>
</template>

<script>
import ListSpray from '@/modules/reports/components/season-report/performance/ListSpray.vue'
import SpraysNotAvaliable from '@/modules/reports/components/season-report/performance/SpraysNotAvaliable.vue'
import { convertToTargetUnit } from '@/components/Form/converter'
import HarvestUnavailableAlert from '@/modules/reports/components/season-report/performance/HarvestUnavailableAlert.vue'

export default {
  name: 'HeaderVariety',
  props: {
    sowing: { type: Object, required: true },
    plot: { type: Object, required: true },
  },

  components: {
    ListSpray,
    SpraysNotAvaliable,
    HarvestUnavailableAlert,
  },

  computed: {
    sowingDateFormatted() {
      return this.sowing.sowing_date.formatDate()
    },
    sowingHasProduction() {
      return this.sowing.sowing_production != null
    },

    productivity() {
      return convertToTargetUnit(
        this.sowing?.harvest?.bags_hectare ?? 0,
        'performance'
      )
    },

    crop() {
      return 'crop.' + this.sowing.variety.crop.toLowerCase()
    },

    sowingArea() {
      return convertToTargetUnit(this.sowing.sowing_area, 'area')
    },

    sowingProduction() {
      return convertToTargetUnit(this.sowing.sowing_production, 'weight')
    },
  },
}
</script>

<style lang="scss" scoped>
hr {
  background-color: #aab2a9;
  height: 1px;
  margin-left: 30px;
  margin-right: 30px;
  opacity: 0.3;
}

.header-variety__stage-circle {
  svg {
    circle {
      fill: #e3f8e1;
    }
  }
  label {
    color: #3f4e3c !important;
    font-size: 14px !important;
    font-weight: normal !important;
    z-index: 999;
  }
}

.header-variety label {
  font-size: 12px;
  color: #aab2a9;
  font-weight: bold;
}

.header-variety p {
  font-weight: bold;
  font-size: 20px;
  color: grey;
}

.variety {
  color: #071505 !important;
  font-size: 28px;
}

@media print {
  .header-variety label {
    font-size: 9px !important;
  }

  .header-variety p {
    font-size: 15px;
  }

  .variety {
    font-size: 18px;
  }
}
</style>
