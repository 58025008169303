<template>
  <v-container class="list-spray">
    <div class="pa-10">
      <v-row class="pa-3">
        <p style="color: grey">
          <font-awesome-icon
            class="fa-lg mb-1 color-custom-green"
            icon="spray-can"
          />
          <label class="ml-5 sprays">{{ $t('report.sprays') }}</label>
        </p>
      </v-row>
      <v-row v-for="spray in sprays" :key="spray.id" class="page-break-inside">
        <v-col cols="2">
          <div v-if="spray.applied_at">
            <label>{{ $t('report.already_applied_on_the_day') }}</label>
            <p>
              {{ formatDate(spray.applied_at) }}
            </p>
          </div>
          <div v-else>
            <label>{{ $t('report.next_spray') }}</label>
            <p style="color: grey">{{ $t('report.to_confirm') }}</p>
          </div>
        </v-col>
        <v-col cols="10">
          <v-row v-for="defensive in spray.defensives" :key="defensive.id">
            <v-col cols="10" class="pa-1">
              <label v-if="spray.applied_at">{{ $t('report.confirm') }}</label>
              <label v-else>{{ $t('report.to_confirm') }}</label>
              <p>{{ defensive.name }}</p>
            </v-col>
            <v-col cols="2" class="pa-1">
              <label>{{ $t('report.quantity') }}</label>
              <p>{{ defensive.quantity }} {{ defensive.unit }}</p>
            </v-col>
          </v-row>

          <v-row>
            <div style="background-color: grey; width: 100%; height: 1px"></div>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'ListSpray',
  props: {
    sprays: { type: Array, required: true },
  },

  methods: {
    formatDate(date) {
      return date.formatDate()
    },
  },
}
</script>

<style scoped>
.backgroundlightGray {
  background: #f0f0f0;
}

.list-spray label {
  font-size: 12px;
  color: #aab2a9;
  font-weight: bold;
}

.list-spray p {
  font-size: 16px;
  color: #aab2a9;
}

hr {
  margin-left: 0px;
  margin-right: 30px;
}

.sprays {
  color: grey;
  font-size: 20px !important;
}

.color-custom-green {
  color: green;
  opacity: 0.6;
}

@media print {
  .title-performance {
    font-size: 22px;
  }

  .list-spray label {
    font-size: 6px;
  }

  .list-spray p {
    font-size: 10px;
  }

  .page-break-inside {
    break-inside: avoid;
  }
}
</style>
