<template>
  <v-container>
    <footer class="footer">
      <v-row>
        <v-col cols="5" class="d-flex justify-center align-center">
          <h3>
            <b>{{ account.user.name }}</b>
          </h3>
        </v-col>
        <v-col cols="4" class="d-flex justify-start">
          <v-divider class="vertical-divider" vertical> </v-divider>
          <font-awesome-icon
            class="fa-sm ml-3 mt-2"
            style="color: #aab2a9; opacity: 0.6"
            icon="calendar-alt"
          />
          <div>
            <label class="ml-5">{{ $t('report.printed_day') }}</label>
            <p class="ml-5">
              {{ printDate }}
            </p>
          </div>
        </v-col>
        <v-col cols="3" class="d-flex justify-start align-center">
          <div class="logo-account">
            <v-img :src="account.logo" eager></v-img>
            {{ siteUrl }}
          </div>
        </v-col>
      </v-row>
    </footer>
  </v-container>
</template>

<script>
export default {
  name: 'SeasonReportFooter',
  props: {
    account: { type: Object, required: true },
  },

  computed: {
    printDate() {
      return new Date().toLocaleDateString()
    },
    siteUrl() {
      return process.env.VUE_APP_URL_SITE
    },
  },
}
</script>

<style scoped>
.footer .v-image {
  max-height: 35px !important;
  max-width: 120px !important;
}

.footer label {
  font-size: 10px;
  color: #aab2a9;
}

.footer p {
  font-size: 15px;
  color: grey;
}

.footer .logo-account {
  color: #aab2a9;
  font-size: 14px;
}
</style>
