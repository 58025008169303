<template>
  <v-col cols="4" class="d-flex align-center justify-center">
    <div class="header-plot__insert-harvest-info py-5 px-6">
      <label>{{ $t('report.productivity') }}</label>

      <div>
        <font-awesome-icon
          class="color-green fa-lg mr-2"
          icon="exclamation-circle"
        />
        {{ $t('report.harvest_unavailable') }}
      </div>
    </div>
  </v-col>
</template>

<script>
export default { name: 'HarvestUnavailableAlert' }
</script>

<style scoped>
.header-plot__insert-harvest-info {
  background-color: #f5f5f5;
  color: #3f4e3c;
}

.color-green {
  color: #39af49;
}
</style>
