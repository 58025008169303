<template>
  <v-container>
    <v-card class="header-plot">
      <v-row class="pa-5">
        <v-col cols="3" class="d-flex align-center">
          <div>
            <label>{{ $t('report.plot') }}</label>
            <p style="color: black">
              {{ plot.name }}
            </p>
          </div>
        </v-col>
        <v-col cols="2" class="d-flex align-center justify-center">
          <div>
            <label>{{ $t('report.area') }}</label>
            <p class="d-flex justify-end">
              {{ area }} {{ $unitMeasures['area'][$currentLanguage()] }}
            </p>
          </div>
        </v-col>
        <v-col cols="2" class="d-flex align-center justify-center">
          <div>
            <label>{{ $t('report.area_sowing') }}</label>
            <p class="d-flex justify-end">
              {{ sowingArea }} {{ $unitMeasures['area'][$currentLanguage()] }}
            </p>
          </div>
        </v-col>
        <v-col
          v-if="plot.production"
          cols="2"
          class="d-flex align-center justify-center"
        >
          <div>
            <label>{{ $t('report.production') }}</label>
            <p class="d-flex justify-end">
              {{ production }} {{ $unitMeasures['weight'][$currentLanguage()] }}
            </p>
          </div>
        </v-col>
        <v-col
          v-if="plot.productivity"
          cols="3"
          class="d-flex align-center justify-center"
        >
          <div>
            <label>{{ $t('report.productivity') }}</label>
            <h3>
              <p class="d-flex justify-end">
                {{ productivity }}
                {{ $unitMeasures['performance'][$currentLanguage()] }}
              </p>
            </h3>
          </div>
        </v-col>
        <harvest-unavailable-alert v-if="noHarvest"></harvest-unavailable-alert>
      </v-row>
      <div class="grey-botton-bar"></div>
    </v-card>
  </v-container>
</template>

<script>
import HarvestUnavailableAlert from '@/modules/reports/components/season-report/performance/HarvestUnavailableAlert.vue'
import { convertToTargetUnit } from '@/components/Form/converter'

export default {
  name: 'HeaderPlot',
  components: { HarvestUnavailableAlert },

  props: {
    plot: {
      type: Object,
      required: true,
    },
  },

  computed: {
    noHarvest() {
      return this.plot.production == null && this.plot.productivity == null
    },
    area() {
      return convertToTargetUnit(this.plot.area, 'area')
    },
    sowingArea() {
      return convertToTargetUnit(this.plot.total_sowing_area, 'area')
    },
    production() {
      return convertToTargetUnit(this.plot.production, 'weight')
    },
    productivity() {
      return convertToTargetUnit(this.plot.productivity, 'performance')
    },
  },
}
</script>

<style scoped>
.header-plot label {
  font-size: 12px;
  color: #aab2a9;
  font-weight: bold;
}

.header-plot p {
  font-weight: bold;
  font-size: 20px;
  color: grey;
}

.grey-botton-bar {
  background: grey;
  min-height: 5px;
}

@media print {
  .header-plot label {
    font-size: 10px !important;
  }
  .header-plot p {
    font-size: 16px;
  }
}
</style>
