<template>
  <v-container>
    <v-row class="ml-5" style="font-size: 30px; font-weight: bold">
      <v-col class="d-flex justify-start title-performance">
        {{ $t('report.performance') }}
      </v-col>
    </v-row>
    <div v-for="plot in plots" :key="plot.id">
      <header-plot :plot="plot"></header-plot>
      <header-variety
        v-for="sowing in plot.sowings"
        :key="sowing.id"
        :sowing="sowing"
        :plot="plot"
      ></header-variety>
    </div>
  </v-container>
</template>

<script>
import HeaderPlot from '@/modules/reports/components/season-report/performance/HeaderPlot.vue'
import HeaderVariety from '@/modules/reports/components/season-report/performance/HeaderVariety.vue'

export default {
  name: 'Performance',
  props: {
    plots: { type: Array, required: true },
  },

  components: {
    HeaderPlot,
    HeaderVariety,
  },
}
</script>

<style scoped>
.container {
  padding-left: 5px;
  padding-right: 5px;
}

@media print {
  .title-performance {
    font-size: 22px;
  }

  .page-break-end {
    page-break-after: always;
  }
}
</style>
