import http from '@/utils/http'

const reports = {
  program: (id) => http.get(`/programas/${id}`),
  seasonReport: (id, startPeriod, endPeriod, isSoybean, isWheat) => {
    const params = {
      isSoybean: isSoybean,
      isWheat: isWheat,
    }

    if (startPeriod && endPeriod) {
      params.startPeriod = startPeriod
      params.endPeriod = endPeriod
    }

    return http.get(`/reports/${id}/season-report`, { params })
  },
}

export default reports
